import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'

const VinosPage = () => (
  <Layout>
    <h1>NUESTROS VINOS</h1>
    <p>En esta sección podrás acceder a toda la información sobre todos los vinos de Bodega Iniesta.</p>
    <ul>
      <li>
        <Link to="/vinos/corazon-loco-tinto">Corazón Loco Tinto</Link>
      </li>
      <li>
        <Link to="/vinos/corazon-loco-blanco">Corazón Loco Blanco</Link>
      </li>
      <li>
        <Link to="/vinos/dulce-corazon">Dulce Corazón</Link>
      </li>
      <li>
        <Link to="/vinos/minuto-116-tinto">Minuto 116</Link>
      </li>
      <li>
        <Link to="/vinos/corazon-spumante-rosado">Corazón Spumante Rosado</Link>
      </li>
    </ul>
  </Layout>
)

export default VinosPage
